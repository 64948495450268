import { differenceInHours } from 'date-fns';

import { KLARNA_COUNTRIES, KLARNA_MAX_IN_EUROS } from '../constants/payment';
import {
  PRODUCT_TAG_ALLOW_MULTIPLE_ITEMS,
  PRODUCT_TAG_COA_2_YEAR_DISPATCH,
  PRODUCT_TAG_DISABLE_CRYPTO,
  PRODUCT_TAG_DISABLE_EXPRESS,
  PRODUCT_TAG_DISABLE_PAYPAL,
  PRODUCT_TAG_ENQUIRE_ONLY_NO_PRICE,
  PRODUCT_TAG_FEW_LEFT,
  PRODUCT_TAG_FREE_SHIPPING,
  PRODUCT_TAG_HANDFINISHED,
  PRODUCT_TAG_NO_COA,
  PRODUCT_TAG_NO_RESALE_AGREEMENT,
  PRODUCT_TAG_NO_TAX_CHARGED,
  PRODUCT_TAG_OPEN_EDITION,
  PRODUCT_TAG_PINNED_ON_HOMEPAGE,
  PRODUCT_TAG_SAME_PRICE,
  PRODUCT_TAG_SOLD_OUT,
  PRODUCT_VARIANT_TITLE_PUBLIC,
} from '../constants/product';
import { DrawByProductId } from '../features/draws/types';
import { combineSorters } from '../helpers/sorting';
import type {
  AvailabilityFilter,
  FinishFilter,
  MediumFilter,
  ReleaseFilter,
  ShippingFilter,
} from '../types/Filters';
import {
  ProductStoryblok,
  ProductV2Storyblok,
} from '../types/generated-storyblok';
import { CountryCode } from '../types/Locations';
import type {
  FrameOption,
  FrameOptionConsolidated,
  ProductBenefits,
  ProductTag,
} from '../types/Products';
import type {
  CollectionSetProduct,
  ProductPurchaseDetails,
  ProductPurchasingController,
  ShopifyProductToCheckout,
} from '../types/Purchasing';
import {
  ShopifyGenAllProductsQuery,
  ShopifyGenGetCollectionByIdQuery,
} from '../types/shopifygen';
import { isNotNullPredicate } from './arrays';
import { framedByDefault } from './frames';

export const selectProductTag = (
  tags: string[],
): Extract<ProductTag, Availability> => {
  if (tags.includes('Sold out')) {
    return 'Sold out';
  }
  if (tags.includes('Coming soon')) {
    return 'Coming soon';
  }
  return 'Available';
};

export const selectMediumTag = (tags: string[]): PrintMedium => {
  // The tag for print medium is not consistently labeled in Shopify, unlike the Product tags.
  const lowerCaseTags = tags.map((tag) => tag.toLocaleLowerCase());

  if (lowerCaseTags.includes('print edition')) {
    return 'Print edition';
  }
  if (lowerCaseTags.includes('sculpture edition')) {
    return 'Sculpture edition';
  }
  if (lowerCaseTags.includes('studio works')) {
    return 'Studio works';
  }
  if (lowerCaseTags.includes('collectible')) {
    return 'Collectible';
  }
  if (lowerCaseTags.includes('nfts')) {
    return 'NFTs';
  }
  return 'Other';
};

export const tagsToExcludeCountries = (tags: string[]): CountryCode[] => {
  return tags
    .filter((tag) => tag.toLowerCase().startsWith('exclude_price_threshold:'))
    .map((tag) => tag.split(':', 2)[1].toUpperCase() as CountryCode);
};

export const mapShopifyProductsToProducts = (
  items: ShopifyGenAllProductsQuery['products']['edges'][number]['node'][],
): Product[] => {
  return items?.map((item) => {
    const tags = item.tags || [];
    const productVariantPublic = item.variants.edges.find(
      (v) =>
        v.node.title.toLowerCase() ===
        PRODUCT_VARIANT_TITLE_PUBLIC.toLowerCase(),
    );

    return {
      id: item.id,
      image: item.featuredImage ? item.featuredImage.url : '',
      link: item.onlineStoreUrl || '',
      artist: item.artistName?.value || '',
      title: item.title,
      price:
        productVariantPublic?.node.price.amount ||
        item.priceRange.maxVariantPrice.amount,
      date: item.launchDate?.value || '',
      manifoldListingId: Number(item.manifoldListingId?.value) || '',
      auctionFloorPrice: item.auctionFloorPrice?.value || '',
      editionSize: Number(item.editionSize?.value) || null,
      availability: selectProductTag(tags),
      medium: selectMediumTag(tags),
      productType: item.productType,
      currencyCode: item.priceRange.maxVariantPrice.currencyCode,
      createdAt: item.createdAt ? item.createdAt : '',
      releaseType: item.releaseType?.value || 'public',
      fewLeft: tags.includes(PRODUCT_TAG_FEW_LEFT),
      freeShipping: tags.includes(PRODUCT_TAG_FREE_SHIPPING),
      samePrice: tags.includes(PRODUCT_TAG_SAME_PRICE),
      pinnedOnHomepage: tags.includes(PRODUCT_TAG_PINNED_ON_HOMEPAGE),
      noresaleAgreement: tags.includes(PRODUCT_TAG_NO_RESALE_AGREEMENT),
      handFinished: tags.includes(PRODUCT_TAG_HANDFINISHED),
      noTaxCharged: tags.includes(PRODUCT_TAG_NO_TAX_CHARGED),
      allowMultipleProducts: tags.includes(PRODUCT_TAG_ALLOW_MULTIPLE_ITEMS),
      enquireOnlyNoPrice: tags.includes(PRODUCT_TAG_ENQUIRE_ONLY_NO_PRICE),
      launchDate: item.launchDate?.value ?? null,
      closingDate: item.closingDate?.value ?? null,
      cryptoPrice: item.cryptoPrice?.value ?? null,
      collectionSet: item.collectionSet?.value ?? null,
      maximumSalesCapacity: item.maximumSalesCapacity?.value ?? null,
      full_slug: item.handle,
      excludeFromRegions: tagsToExcludeCountries(tags),
      framingOffered: item.framingOffered?.value || null,
    } as Product;
  });
};

export const addSlugToProducts = (
  products: Product[],
  storyblokProducts: Product[],
): Product[] => {
  return products.map((product: Product, index: number) => {
    return {
      ...product,
      full_slug: `${storyblokProducts[index].full_slug}`,
    };
  });
};

export const sortProducts = (
  products: Array<Product>,
  order: 'DESC' | 'ASC',
) => {
  if (order === 'ASC') {
    return products.sort(combineSorters([sortByDateAsc, sortByArtistName]));
  }
  return products.sort(combineSorters([sortByDateDesc, sortByArtistName]));
};

export const sortByDateAsc = (a: Product, b: Product) => {
  return a.date && b.date ? a.date.localeCompare(b.date) : 0;
};

export const sortByDateDesc = (a: Product, b: Product) => {
  return a.date && b.date ? b.date.localeCompare(a.date) : 0;
};

export const sortByArtistName = (a: Product, b: Product) => {
  return a.artist.localeCompare(b.artist);
};

export const cmToIn = (cm: number) => {
  return cm / 2.54;
};

export const filterProducts = (
  products: Product[],
  filters: {
    availability?: AvailabilityFilter;
    finishes?: FinishFilter[];
    mediums?: MediumFilter[];
    releaseTypes?: ReleaseFilter[];
    shipping?: ShippingFilter;
  },
) => {
  let filtered = products;

  // Availability filter
  if (filters.availability !== undefined && filters.availability.length > 0) {
    filtered = filtered.filter((p) => {
      return (
        filters.availability === 'All' ||
        (p.availability?.toLocaleLowerCase() === 'available' &&
          filters.availability === 'Available') ||
        (p.availability?.toLocaleLowerCase() === 'coming soon' &&
          filters.availability === 'Upcoming')
      );
    });
  }

  // Shipping filter
  if (filters.shipping === 'Free') {
    filtered = filtered.filter(({ freeShipping }) => freeShipping);
  }

  // Medium filter
  if (filters.mediums !== undefined && filters.mediums.length > 0) {
    filtered = filtered.filter((p) => {
      return (
        filters.mediums?.includes('All') ||
        (p.medium?.toLocaleLowerCase() === 'print edition' &&
          filters.mediums?.includes('Print editions')) ||
        (p.medium?.toLocaleLowerCase() === 'collectible' &&
          filters.mediums?.includes('Collectibles')) ||
        (p.medium?.toLocaleLowerCase() === 'sculpture edition' &&
          filters.mediums?.includes('Sculpture editions')) ||
        (p.medium?.toLocaleLowerCase() === 'studio works' &&
          filters.mediums?.includes('Studio works')) ||
        (p.medium?.toLocaleLowerCase() === 'nfts' &&
          filters.mediums?.includes('NFTs'))
      );
    });
  }

  // Finishes
  if (filters.finishes !== undefined && filters.finishes.length > 0) {
    filtered = filtered.filter((p) => {
      return p.handFinished && filters.finishes?.includes('Hand-finished');
    });
  }

  // Release type
  if (filters.releaseTypes !== undefined && filters.releaseTypes.length > 0) {
    filtered = filtered.filter((p) => {
      return (
        filters.releaseTypes?.includes('All') ||
        (p.releaseType === 'public' &&
          filters.releaseTypes?.includes('Standard release')) ||
        (p.releaseType === 'private' &&
          filters.releaseTypes?.includes('Available by enquiry')) ||
        (p.releaseType === 'draw' &&
          filters.releaseTypes?.includes('Draw release')) ||
        (p.releaseType === 'timed' &&
          filters.releaseTypes?.includes('Timed release'))
      );
    });
  }

  return filtered;
};

export function getProductBenefits<T extends ProductPurchaseDetails>(
  product: ProductStoryblok | ProductV2Storyblok,
  purchasing: ProductPurchasingController<T>,
  country: CountryCode,
  draw?: DrawByProductId,
): ProductBenefits {
  // Configured in Shopify
  const hasResaleAgreement = Boolean(!purchasing.product?.noResaleAgreement);
  const hasTwoYearCoaDispatch = Boolean(
    purchasing.product?.tags.includes(PRODUCT_TAG_COA_2_YEAR_DISPATCH),
  );
  const hasFreeShipping = Boolean(
    purchasing.product?.tags.includes(PRODUCT_TAG_FREE_SHIPPING),
  );
  const hasPaypalEnabled = !purchasing?.product?.tags.some(
    (tag) =>
      tag === PRODUCT_TAG_DISABLE_PAYPAL || tag === PRODUCT_TAG_DISABLE_EXPRESS,
  );
  const hasCryptoEnabled = !purchasing?.product?.tags.includes(
    PRODUCT_TAG_DISABLE_CRYPTO,
  );
  const hasCryptoDrawEnabled = draw?.cryptoComEnabled ?? false;
  const hasKlarnaEnabled =
    KLARNA_COUNTRIES.includes(country) &&
    purchasing.product?.price !== undefined &&
    purchasing.product?.price < KLARNA_MAX_IN_EUROS;
  const hasTaxesAndCharges = !purchasing?.product?.tags.includes(
    PRODUCT_TAG_NO_TAX_CHARGED,
  );
  const isOpenEdition = Boolean(
    purchasing.product?.tags.includes(PRODUCT_TAG_OPEN_EDITION),
  );
  const allowMultipleProducts = Boolean(
    purchasing?.product?.tags.includes(PRODUCT_TAG_ALLOW_MULTIPLE_ITEMS),
  );

  // Configure in Storyblok
  const hasCOA = purchasing.product?.tags.includes(PRODUCT_TAG_NO_COA) ?? false;
  const hasFramingIncluded = framedByDefault(
    purchasing?.product?.framingOffered,
  );
  const hasWoodenBox =
    product.component === 'product_v2' ? true : !product.noWoodenBox;

  return {
    hasCOA,
    hasTwoYearCoaDispatch,
    hasCryptoEnabled,
    hasCryptoDrawEnabled,
    hasFramingIncluded,
    hasFreeShipping,
    hasKlarnaEnabled,
    hasPaypalEnabled,
    hasResaleAgreement,
    hasTaxesAndCharges,
    hasWoodenBox,
    isOpenEdition,
    allowMultipleProducts,
  };
}

export const hasFewLeftTag = (tags: string[]) => {
  return tags.includes(PRODUCT_TAG_FEW_LEFT);
};

export const isSoldOut = (product: Product) =>
  product.availability === 'Sold out';
export const isAvailable = (product: Product) =>
  product.availability === 'Available';
export const isUpcoming = (product: Product) =>
  product.availability === 'Coming soon';
export const isTimedRelease = (product: Product) =>
  product.releaseType === 'timed';
export const isDrawRelease = (product: Product) =>
  product.releaseType === 'draw';
export const isPrivateRelease = (product: Product) =>
  product.releaseType === 'private';
export const isAuctionRelease = (product: Product) =>
  product.releaseType === 'auction' ||
  product.releaseType === 'nft_ranked_auction';
export const isReleaseTypeAuction = (releaseType: string) =>
  releaseType === 'auction' || releaseType === 'nft_ranked_auction';

export const isInNext24Hours = (date: Date) =>
  differenceInHours(date, new Date(), { roundingMethod: 'ceil' }) <= 24;

export const isInNextSixDays = (date: Date) =>
  differenceInHours(date, new Date(), { roundingMethod: 'ceil' }) <= 144;

export type ProductState = 'upcoming' | 'available' | 'closed';

export const customPropertyByKey = (
  attrs: ShopifyProductToCheckout['customProperties'],
  key: string,
) => {
  return attrs?.find((attr) => attr.key === key)?.value || '';
};

export const hasSelectableFrame = (
  product: ProductType | undefined,
  frameIncluded: boolean,
): boolean => {
  const hasFramingOptions = Boolean((product?.frameOptions?.length ?? 0) > 0);

  return Boolean(hasFramingOptions && !frameIncluded);
};

export const getSkuFromVariants = (variants: { sku: string }[]) => {
  const [variant] = variants;
  const [part1, part2] = variant.sku.split('-');
  if (part1 && part2) {
    return `${part1}-${part2}`;
  }
  return null;
};

export const shopifyToStoryblokDate = (
  dateString: string | null | undefined,
) => {
  if (dateString === '' || dateString === null || dateString === undefined) {
    return '';
  }

  const date = new Date(dateString);
  return `${date.toISOString().slice(0, 10)} ${date
    .toISOString()
    .slice(11, 16)}`;
};

// TODO: This whole data concatenation logic will go away after moving frames
// management to Shopify: https://linear.app/avantarte/issue/PTE-1499/simplify-how-we-manage-frames
export function mergeCollectionSetProductsAndFrames({
  allowSoldOut,
  framesShopify,
  framesStoryblok,
  productShopifyId,
  productShopifyVariantTitle,
  productsShopify,
  productsStoryblok,
}: {
  allowSoldOut: boolean;
  productShopifyId: string;
  productShopifyVariantTitle: string;
  productsShopify: NonNullable<
    Required<ShopifyGenGetCollectionByIdQuery>['collection']
  >['products']['nodes'];
  productsStoryblok: Array<ProductV2Storyblok | ProductStoryblok>;
  framesShopify: {
    shopifyFrameId: string;
    title: string;
    variants: {
      shopifyFrameVariantId: string;
      title: string;
      price: number;
    }[];
  }[];
  framesStoryblok: {
    shopifyProductId: string;
    shopifyFrameId: string;
    title: string;
    descriptions: string | undefined;
    images: {
      alt: string;
      src: string;
    }[];
  }[];
}) {
  const products = productsShopify.reduce((acc, productShopify) => {
    const productShopifyVariant = productShopify.variants.edges.find(
      (v) => v.node.title === productShopifyVariantTitle,
    );

    // Collection set should contain only products of chosen variant
    // On product page, it is the public variant
    // On private page, it is the variant selected in the admin panel
    if (!productShopifyVariant) {
      return acc;
    }

    // Do not include products out of stock in the collection set
    if (!productShopifyVariant.node.availableForSale) {
      return acc;
    }

    // The public variant used on the product page should not be included
    // If the product contains the sold out tag
    if (
      productShopifyVariantTitle === PRODUCT_VARIANT_TITLE_PUBLIC &&
      productShopify.tags.includes(PRODUCT_TAG_SOLD_OUT) &&
      !allowSoldOut
    ) {
      return acc;
    }

    const productStoryblok = productsStoryblok.find((productStoryblok) => {
      const productStoryblokId =
        productStoryblok.component === 'product_v2'
          ? productStoryblok.shopifyId
          : (productStoryblok.id[0].id as string);

      return productShopify.id.endsWith(productStoryblokId);
    });

    // Skip merging, if there is a missing Storyblok product
    if (!productStoryblok) {
      return acc;
    }

    return [
      ...acc,
      {
        allowMultipleProducts: productShopify.tags.includes(
          PRODUCT_TAG_ALLOW_MULTIPLE_ITEMS,
        ),
        allowMultipleProductsLimit: productShopify.maximumSalesCapacity?.value
          ? parseInt(productShopify.maximumSalesCapacity.value, 10)
          : Number.MAX_SAFE_INTEGER,
        artist: productShopify.artistName?.value ?? '',
        image: productShopify.featuredImage?.url ?? '',
        initial: productShopify.id.endsWith(productShopifyId),
        isSoldOut: productShopify.tags.includes(PRODUCT_TAG_SOLD_OUT),
        price: Number(productShopifyVariant.node.price.amount),
        quantity: 1,
        selected: productShopify.id.endsWith(productShopifyId),
        shopifyId: productShopify.id,
        shopifyVariantId: productShopifyVariant.node.id,
        title: productShopify.title,
      },
    ];
  }, [] as CollectionSetProduct[]);

  // If the set contains less than 2 products, there is no need of displaying the collection set
  // And fallback to the regular product purchase flow
  if (products.length < 2) {
    return null;
  }

  const frames = framesStoryblok
    .map((frameStoryblok) => {
      const frameShopify = framesShopify.find(
        (frame) => frame.shopifyFrameId === frameStoryblok.shopifyFrameId,
      );

      if (!frameShopify) {
        return null;
      }

      return {
        title: frameStoryblok.title,
        description: frameStoryblok.descriptions,
        images: frameStoryblok.images,
        shopifyProductId: frameStoryblok.shopifyProductId,
        shopifyFrameId: frameStoryblok.shopifyFrameId,
        variants: frameShopify.variants.map((variant) => ({
          title: variant.title,
          price: variant.price,
          shopifyFrameVariantId: variant.shopifyFrameVariantId,
        })),
      };
    })
    .filter(isNotNullPredicate);

  return {
    products,
    frames,
  };
}

export const consolidateFrames = ({
  compositeImageProductShopifyId,
  frameOptions,
  selection,
}: {
  compositeImageProductShopifyId: string;
  frameOptions: Array<FrameOption>;
  selection: Array<{
    id: string;
    quantity: number;
  }>;
}) => {
  const framesRelevantToSelection = frameOptions?.filter(
    ({ shopifyProductId }) =>
      selection.some((selectionItem) => selectionItem.id === shopifyProductId),
  );

  return framesRelevantToSelection.reduce<Array<FrameOptionConsolidated>>(
    (accFrames, item) => {
      const framesSameColour = framesRelevantToSelection.filter(
        ({ title }) => title === item.title,
      );

      // if particular frame colur is not available for all selected products
      // it should not be included in the consolidated results
      if (framesSameColour.length !== selection.length) {
        return accFrames;
      }

      const variantsToInclude = item.variants.reduce<FrameOption['variants']>(
        (accVariants, variant) => {
          const shouldInclude = framesSameColour.every((frameSameColour) =>
            frameSameColour.variants.some((v) => v.title === variant.title),
          );

          if (shouldInclude) {
            accVariants.push(variant);
          }

          return accVariants;
        },
        [],
      );

      // if the consolidated frames do not have at least single variant,
      // it should not be included in the consolidated results
      if (!variantsToInclude.length) {
        return accFrames;
      }

      const sameColourIndex = accFrames.findIndex(
        ({ title }) => title === item.title,
      );

      const setItemQuantity =
        selection.find(({ id }) => id === item.shopifyProductId)?.quantity ?? 1;

      if (sameColourIndex !== -1) {
        // always use composite image of the main product
        const compositeImageUrl = frameOptions.find(
          (option) =>
            option.title === item.title &&
            option.shopifyProductId === compositeImageProductShopifyId,
        );

        if (compositeImageUrl && compositeImageUrl.images.length > 1) {
          /* eslint-disable no-param-reassign */
          accFrames[sameColourIndex].images[1] = {
            src: compositeImageUrl.images[1].src,
            alt: compositeImageUrl.images[1].alt,
          };
        }

        /* eslint-disable no-param-reassign */
        accFrames[sameColourIndex].variants = accFrames[
          sameColourIndex
        ].variants.map((existingVariant) => {
          const newVariant = item.variants.find(
            ({ title }) => title === existingVariant.title,
          );

          if (!newVariant) {
            return existingVariant;
          }

          return {
            ...existingVariant,
            consolidatedVariants: [
              ...existingVariant.consolidatedVariants,
              {
                quantity: setItemQuantity,
                price: newVariant.price,
                shopifyFrameId: item.shopifyFrameId,
                shopifyFrameVariantId: newVariant.shopifyFrameVariantId,
                shopifyProductId: item.shopifyProductId,
              },
            ],
          };
        });

        return accFrames;
      }

      accFrames.push({
        title: item.title,
        description: item.description,
        images: item.images,
        variants: variantsToInclude.map((variantToInclude) => ({
          title: variantToInclude.title,
          consolidatedVariants: [
            {
              quantity: setItemQuantity,
              price: variantToInclude.price,
              shopifyFrameId: item.shopifyFrameId,
              shopifyFrameVariantId: variantToInclude.shopifyFrameVariantId,
              shopifyProductId: item.shopifyProductId,
            },
          ],
        })),
      });

      return accFrames;
    },
    [],
  );
};
