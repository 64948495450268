export function uniq<T>(arr: T[]) {
  return Array.from([...new Set(arr)]);
}

export function uniqOn<T>(arr: T[], predicate: (elem: T) => any) {
  return arr.reduce((acc, elem) => {
    return acc.some((entry) => predicate(entry) === predicate(elem))
      ? acc
      : [...acc, elem];
  }, []);
}

export function compact<T>(arr: T[]) {
  return arr.filter(
    (v): v is Exclude<T, null | undefined> => v !== undefined && v !== null,
  );
}

export function split<T>(arr: T[], predicate: (elem: T) => boolean) {
  return arr.reduce(
    (groups, elem) => {
      if (predicate(elem)) {
        return [[...groups[0], elem], groups[1]];
      }
      return [groups[0], [...groups[1], elem]];
    },
    [[], []],
  );
}

export function castArray(data: string | string[] = []): string[] {
  return Array.isArray(data) ? data : [data];
}

export function chunkDistribution(items: number, columns = 4, rows = 5) {
  return Math.min(Math.ceil(items / columns), rows);
}

export function isNotNullPredicate<T>(entity: T | null) {
  return entity != null;
}
